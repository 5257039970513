





























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ApiService } from '@/services/ApiService';
import { debounce } from 'lodash';
import { ChordProService } from '@/services/ChordProService';


@Component
export default class ChordProPreview extends Vue {
  @Prop()
  chords!: string;

  @Prop()
  title!: string;

  @Prop()
  authors!: string;

  @Prop({type: Boolean, default: false})
  editMode!: boolean;

  @Prop({
    default: '1'
  })
  columns!: string;

  html ='';


  busy = false;
  error = false;
  shadowDOM: ShadowRoot | undefined = undefined;
  contentsElement = document.createElement('div');

  @Watch('chordsSanitized')
  @Watch('columns')
  onInputUpdate(){
    this.getHtmlDebounced();
  }

  async mounted() {
    await this.getHTML();
    this.shadowDOM = (this.$refs.iframe as HTMLElement).attachShadow({mode: "open"});
    this.contentsElement = document.createElement('div');
    this.shadowDOM.appendChild(this.contentsElement);
    this.updateShadowDOM();
  }

  updateShadowDOM(){
    this.contentsElement.innerHTML = this.html;
  }

  get chordsSanitized() {
    let chords = this.chords;
    const charactersSanitize = /(<.*?>|<.*|javascript:|on\w+=".*?"|style=".*?")/gi;

    if (this.editMode) {
        chords = ChordProService.removeDirectivesFromChords(this.chords, this.title, this.authors);
    }

    const splitChords = chords.split(']');
    const sanitizedParts = splitChords.map((part, index) => {
        if (index === 0) {
            return part.replace(charactersSanitize, '');
        }
        return ']' + part.replace(charactersSanitize, '');
    });

    chords = sanitizedParts.join('');
    return chords;
  }

  async getHTML(){
    this.error = false;
    if (this.chords === ''){
      this.html = '';
      this.updateShadowDOM();
      return;
    }

    this.busy = true;
    const params = {
      chords: this.chordsSanitized,
      columns: this.columns
    };

    try {
      this.html = await ApiService.post('/api/RenderChordsAsHtml', params, true);
      this.updateShadowDOM();
      this.busy = false;
    }catch(err){
      this.error = true;
      this.busy = false;
      throw err;
    }

  }

  getHtmlDebounced = debounce(this.getHTML, 500)

}
